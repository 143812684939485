<template>
  <div class="body-login">
    <div class="content-overlay" />
    <div class="content-wrapper">
      <div class="content-header row" />
      <div class="content-body">
        <div class="auth-wrapper auth-cover">
          <div class="auth-inner row m-0">
            <!-- Brand logo-->
            <a
              class="brand-logo"
              href="https://www.icrea.cat"
            />

            <!-- /Brand logo-->
            <!-- Left Text-->
            <div
              class="d-none d-lg-flex col-lg-8 align-items-center p-5"
              :style="{'background': 'url(' + require('@/assets/images/illustration/bg-login.svg') + ') center center no-repeat', 'background-size': 'cover'}"
            >
              <div class="w-100 d-lg-flex align-items-center justify-content-center flex-column px-5">
                <div class="mb-4">
                  <img
                    class="img-fluid"
                    :src="require('@/assets/images/logo/logo-icrea-white-login.svg')"
                    alt="ICREA"
                  >
                </div>
                <!-- <div class="quote">
                  <h1><em>"You never fail until you stop trying"</em></h1>
                  <hr>
                  <p>Albert Einstein</p>
                </div> -->
              </div>
            </div>
            <!-- /Left Text-->
            <!-- Login-->
            <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
              <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title fw-bold mb-1">
                  Welcome to ICREA
                </h2>
                <p class="card-text mb-2">
                  Please register to apply for the call.
                </p>
                <form
                  class="auth-login-form mt-2"
                  action="index.html"
                  method="POST"
                >
                  <div class="mb-1 form-group required">
                    <label
                      class="form-label"
                      for="register-name"
                    >Name</label>
                    <input
                      id="register-name"
                      v-model="userRegisterData.first_name"
                      class="form-control"
                      type="text"
                      name="register-name"
                      placeholder="Isaac"
                      aria-describedby="register-name"
                      autofocus=""
                      tabindex="1"
                    >
                  </div>
                  <div class="mb-1 form-group required">
                    <label
                      class="form-label"
                      for="register-name"
                    >Last name 1</label>
                    <input
                      id="register-lastname1"
                      v-model="userRegisterData.last_name_1"
                      class="form-control"
                      type="text"
                      name="register-Lastname1"
                      placeholder="Newton"
                      aria-describedby="register-Last name"
                      autofocus=""
                      tabindex="2"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      class="form-label"
                      for="register-name"
                    >Last name 2</label>
                    <input
                      id="register-lastname2"
                      v-model="userRegisterData.last_name_2"
                      class="form-control"
                      type="text"
                      name="register-Lastname2"
                      placeholder="Ayscough"
                      aria-describedby="register-Lastname2"
                      autofocus=""
                      tabindex="3"
                    >
                  </div>
                  <div class="mb-1 form-group required">
                    <label
                      class="form-label"
                      for="login-email"
                    >Email</label>
                    <input
                      id="login-email"
                      v-model="userRegisterData.email"
                      class="form-control"
                      type="text"
                      name="login-email"
                      placeholder="john@example.com"
                      aria-describedby="login-email"
                      autofocus=""
                      tabindex="4"
                    >
                  </div>
                  <div class="mb-1 form-group required">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="login-password"
                      >Password</label>
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                      <input
                        id="login-password"
                        v-model="userRegisterData.password"
                        class="form-control form-control-merge"
                        :type="type"
                        name="login-password"
                        placeholder="············"
                        aria-describedby="login-password"
                        tabindex="5"
                      ><span
                        class="input-group-text cursor-pointer"
                        @click="showPassword"
                      ><i data-feather="eye" /></span>
                    </div>
                  </div>
                  <!-- <button class="btn btn-primary w-100" tabindex="5">Register</button> -->
                  <a
                    v-if="!loading"
                    href="#"
                    class="btn btn-primary w-100"
                    tabindex="5"
                    data-bs-toggle="modal"
                    data-bs-target="#modalAcceptPrivacy"
                    @click="sendRegistration"
                  >Register</a>
                  <b-button
                    v-else
                    type="submit"
                    disabled
                  >
                    <div class="loading">
                      <div class="effect-1 effects" />
                      <div class="effect-2 effects" />
                      <div class="effect-3 effects" />
                    </div>
                  </b-button>
                </form>
                <p class="text-center mt-2">
                  <span>The system is running on a new platform, and old accounts are no longer valid. All applicants are kindly requested to create new accounts for the 2024 ICREA call.</span>
                </p>

              </div>
            </div>
            <!-- /Login-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

export default {
  data() {
    return {
      userRegisterData: {
        first_name: '',
        last_name_1: '',
        last_name_2: '',
        email: '',
        password: '',
      },
      type: 'password',
      loading: false,
    }
  },
  methods: {
    sendRegistration() {
      // !Object.values(this.userRegisterData).some(x => x === '')
      this.loading = true
      if (
        this.userRegisterData.first_name !== ''
        && this.userRegisterData.last_name_1 !== ''
        && this.userRegisterData.email !== ''
        && this.userRegisterData.password !== ''
      ) {
        const routeOkUrl = this.$router.resolve({
          name: 'confirmed-email',
        }).href

        const absoluteURL = new URL(routeOkUrl, window.location.origin).href

        this.userRegisterData.route_ok = absoluteURL

        if (this.userRegisterData.last_name_2 === '') {
          this.userRegisterData.last_name_2 = null
        }

        this.$store.dispatch('auth/registerApplicant', this.userRegisterData).then(() => {
          this.$router.push({ name: 'front.register-ok' })
        }).catch(exception => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: exception,
              icon: 'ErrorIcon',
              variant: 'error',
            },
          })
        })
      } else {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please, fill up all the required fields',
            icon: 'ErrorIcon',
            variant: 'error',
          },
        })
      }
    },
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text'
      } else {
        this.type = 'password'
      }
    },
  },

}
</script>

<style>

</style>
